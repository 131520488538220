var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table v-middle text-nowrap bg-transparent"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"variant":"primary","text":"EA"}})],1),_vm._m(1)])]),_c('td',[_vm._v("Single Use")]),_c('td',[_vm._v("John Doe")]),_c('td',[_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"danger"}},[_vm._v("Angular")])],1),_c('td',[_vm._v("46")]),_c('td',[_vm._v("356")]),_vm._m(2)]),_c('tr',[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"variant":"danger","text":"MA"}})],1),_vm._m(3)])]),_c('td',[_vm._v("Single Use")]),_c('td',[_vm._v("Venessa Fern")]),_c('td',[_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"info"}},[_vm._v("Vue Js")])],1),_c('td',[_vm._v("46")]),_c('td',[_vm._v("356")]),_vm._m(4)]),_c('tr',[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"variant":"info","text":"MP"}})],1),_vm._m(5)])]),_c('td',[_vm._v("Single Use")]),_c('td',[_vm._v("John Doe")]),_c('td',[_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"success"}},[_vm._v("Bootstrap")])],1),_c('td',[_vm._v("46")]),_c('td',[_vm._v("356")]),_vm._m(6)]),_c('tr',[_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"variant":"success","text":"AA"}})],1),_vm._m(7)])]),_c('td',[_vm._v("Single Use")]),_c('td',[_vm._v("John Doe")]),_c('td',[_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"warning"}},[_vm._v("React")])],1),_c('td',[_vm._v("46")]),_c('td',[_vm._v("356")]),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-light"},[_c('tr',{},[_c('th',{staticClass:"border-0"},[_vm._v("Products")]),_c('th',{staticClass:"border-0"},[_vm._v("License")]),_c('th',{staticClass:"border-0"},[_vm._v("Support Agent")]),_c('th',{staticClass:"border-0"},[_vm._v("Technology")]),_c('th',{staticClass:"border-0"},[_vm._v("Tickets")]),_c('th',{staticClass:"border-0"},[_vm._v("Sales")]),_c('th',{staticClass:"border-0"},[_vm._v("Earnings")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('h5',{staticClass:"mb-0"},[_vm._v("Elite Admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"mb-0"},[_vm._v("$2850.06")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('h5',{staticClass:"mb-0"},[_vm._v("Monster Admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"mb-0"},[_vm._v("$2850.06")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('h5',{staticClass:"mb-0"},[_vm._v("Material Pro Admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"mb-0"},[_vm._v("$2850.06")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ample Admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('h5',{staticClass:"mb-0"},[_vm._v("$2850.06")])])
}]

export { render, staticRenderFns }